* {
  text-decoration: none;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Open Sans';
}

body {
  background: #F8F8F8;
  height: 100vh;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans.ttf');
  font-style: normal;
  font-display: swap;
}