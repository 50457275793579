.textFieldWrapper {
    margin-bottom: 2vw;
    flex-direction: column;
    position: relative;
    display: flex;
}

.functionalityWrapper {
    height: 4vw;
    flex-direction: column;
    display: flex;
}

.errorText {
    font-size: 0.7vw;
    color: red;
}

.inputField~span {
    transition: 0.3s ease;
    opacity: 0;
}

.inputField:focus~span {
    transition: 0.3s ease;
    opacity: 1;
}

.labelText {
    font-size: 1.471vw;
    margin-bottom: 0.735vw;
    font-family: Calibri;
}

.inputWrapper {
    align-items: center;
    position: relative;
    display: flex;
}

.iconWrapper {
    position: absolute;
    cursor: pointer;
    right: 1vw;
}

.inputField {
    -webkit-text-fill-color: #000 !important;
    border: 0.1vw solid gray;
    border-radius: 0.4vw;
    width: 24vw;
    outline: none;
    padding: 0 1vw;
    font-size: 1vw;
    height: 3vw;
    caret-color: #000;
}

.inputField:-webkit-autofill,
.inputField:-webkit-autofill:hover,
.inputField:-webkit-autofill:focus,
.inputField:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
}