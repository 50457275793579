.dashboardWrapper {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
}

.searchBarWrapper {
    justify-content: space-between;
    align-items: center;
    padding: 1vw 0;
    display: flex;
    width: 80%;
}