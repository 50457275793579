.searchBarWrpr {
    position: relative;
    margin-top: 1.25vw;
}

.searchWrapper {
    align-items: center;
    position: relative;
    display: flex;
}

.searchIconWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    right: 1%;
}

.searchField {
    -webkit-text-fill-color: #000 !important;
    border: 0.1vw solid gray;
    border-radius: 0.4vw;
    width: 68vw;
    outline: none;
    padding: 0 1vw;
    font-size: 1vw;
    height: 2.5vw;
    caret-color: #000;
}