.tableContainer {
    width: 80% !important;
    margin-top: 0.5vw;
    margin-bottom: 1vw;
}

.tableBody {
    height: 80vh;
}

.missingResultsText {
    justify-content: center;
    align-items: center;
    height: 25vh;
    font-size: 2.5vw;
    display: flex;
}

.errorText {
    justify-content: center;
    align-items: center;
    height: 25vh;
    font-size: 2.5vw;
    display: flex;
    color: rgb(73, 7, 7);
}

.tableLoader {
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.tableCell {
    font-size: 0.75vw !important;
}

.dropdownMenuLabel {
    font-family: 'Open Sans';
    margin-bottom: 0.2vw;
    font-size: 0.75vw;
}

.dropdownMenuWrapper {
    flex-direction: column;
    display: flex;
}

.dropdownMenu {
    font-size: 0.85vw !important;
    width: 9.5vw;
    height: 2.5vw;
}

.MuiSelect-icon {
    width: 1vw !important;
}

.MuiSelect-select.MuiSelect-select {
    padding: 7%;
}

.MuiInputBase-root {
    border-radius: 0.4vw !important;
    outline: none;
}

.MuiTableRow-root {
    height: 5%;
}
