.loginPageWrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    display: flex;
}

.loginBlockWrapper {
    border-radius: 0.25vw;
    padding: 1vw 1vw;
    background: #fff;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labelText {
    align-self: flex-start;
    font-family: 'Open Sans';
    font-size: 0.9vw;
    margin-bottom: 0.2vw;
    /* margin-left: 1vw; */
}

.loginText {
    font-family: 'Open Sans';
    margin-bottom: 1vw;
    font-size: 1.5vw;
    color: #000;
}