.headerWrapper {
    justify-content: space-between;
    background: #0B486B;
    align-items: center;
    padding: 0 1vw;
    display: flex;
    width: 100%;
    height: 3vw;
}

.headerTitle {
    font-size: 1.5vw !important;
    color: #fff;
}